<template>
  <div class="wrapper-main__content price-projects-page">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <VocabularyTitle :title="$t('sideBar.priceProjects')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex flex-wrap mt-3 gap-3 w-100">
          <div class="d-flex w-100 gap-3 justify-content-between">
            <div class="d-flex gap-3">
              <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />

              <datepicker-wrapper
                id="filter-day-from-datepicker"
                v-model="filters.date_from"
                reset-button
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
                style="width: 200px"
                @input="filterDateFrom"
              />
              <datepicker-wrapper
                id="filter-day-to-datepicker"
                v-model="filters.date_to"
                reset-button
                :min="filters.date_from"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
                style="width: 200px"
                @input="pageClick(1)"
              />

              <MultiSelect
                v-model="filters.channel"
                label="name"
                track-by="id"
                :options="filterChannelsList"
                :placeholder="$t('table.selectChannel')"
                class="w-250px"
                @input="pageClick(1)"
              ></MultiSelect>
            </div>

            <search-input v-model="searchElements" @searchTable="pageClick(1)" />
          </div>
          <div class="d-flex w-100 gap-3">
            <label class="ml-2 mb-0 mt-2">{{ $t('table.type') }}:</label>
            <b-form-group v-slot="{ ariaDescribedTypeFilterby }" class="mb-0 mt-2">
              <b-form-radio v-model="filters.type" inline :aria-describedby="ariaDescribedTypeFilterby" name="filter-type-radios" value="">All</b-form-radio>
              <b-form-radio v-model="filters.type" inline :aria-describedby="ariaDescribedTypeFilterby" name="filter-type-radios" value="time_slots">
                Time slots
              </b-form-radio>
              <b-form-radio v-model="filters.type" inline :aria-describedby="ariaDescribedTypeFilterby" name="filter-type-radios" value="fix_grp">
                Fix GRP
              </b-form-radio>
            </b-form-group>
          </div>
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover" style="white-space: nowrap">
          <thead>
            <th scope="col">
              <div class="resizable-div__name">
                <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
                {{ $t('table.name') }}
              </div>
            </th>
            <th scope="col">
              <sort-arrows :sort-string="sortString" :column="'channel_id,'" @click="sortTable('channel_id,', $event)" />
              {{ $t('channelModal.channel') }}
            </th>
            <th scope="col">
              <sort-arrows :sort-string="sortString" :column="'type,'" @click="sortTable('type,', $event)" />
              {{ $t('table.type') }}
            </th>
            <th scope="col">
              <sort-arrows :sort-string="sortString" :column="'date_from,'" @click="sortTable('date_from,', $event)" />
              {{ $t('table.period') }}
            </th>
          </thead>
          <tbody>
            <tr v-if="priceProjectsStatus !== 'success'">
              <td colspan="4"><SpinnerLoader :loading="priceProjectsStatus" /></td>
            </tr>
            <tr v-for="(row, index) in tableData" :key="index">
              <td>
                <button
                  v-if="canView"
                  :title="canEdit ? $t('table.edit') : $t('table.details')"
                  class="btn-icon d-inline-block"
                  @click="showModalPriceProjects('edit', row)"
                >
                  <b-icon icon="pencil"></b-icon>
                </button>
                <button v-if="canDelete" class="btn-icon d-inline-block mr-4" @click="showDeleteModalPriceProjects(row)">
                  <b-icon icon="trash" :title="$t('table.delete')"></b-icon>
                </button>
                {{ row.name }}
              </td>
              <td>{{ row.channel_name }}</td>
              <td>{{ row.type === 'time_slots' ? 'Time Slots' : 'Fix GRP' }}</td>
              <td>{{ row.date_from | convertDate }} - {{ row.date_to | convertDate }}</td>
            </tr>
            <tr v-if="priceProjectsStatus === 'success' && tableData && tableData.length === 0">
              <td colspan="4">
                <div class="d-flex w-100 justify-content-center">
                  {{ $t('table.noContent') }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <div class="d-flex justify-content-between">
          <b-button variant="primary" @click="showModalPriceProjects('add')">
            {{ $t('table.add') }}
          </b-button>
        </div>
      </div>
      <!--FOOTER-->

      <ModalPriceProjects :can-edit="canEdit" :current-price-project="currentPriceProject" :modal-type="modalType" @update="paramsData" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import PaginateWrapper from '../components/PaginateWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import convertDate from '@/filters/convertDate';
import sortTableMixin from '@/mixins/sortTable.js';
import ModalPriceProjects from '@/components/PriceProjects/ModalPriceProjects';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import SortArrows from '../components/SortArrows';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';

export default {
  name: 'PriceProjects',
  components: { PaginateWrapper, SpinnerLoader, VocabularyTitle, MultiSelect, SearchDropdown, SearchInput, ModalPriceProjects, SortArrows, DatepickerWrapper },
  filters: {
    convertDate,
  },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
    date_from: { type: String, default: undefined },
    date_to: { type: String, default: undefined },
    selected_type: { type: String, default: undefined },
    channel_id: { type: [String, Number], default: undefined },
  },

  data() {
    return {
      rowId: '',
      tableData: [],
      paginationData: '',
      searchElements: '',
      filterPage: 1,
      selectedPerPage: '100',
      modalName: '',
      filters: {
        channel: null,
        date_from: null,
        date_to: null,
        type: '',
      },

      currentPriceProject: undefined,
      modalType: 'add',
      filterChannelsList: [],
      loading: true,
      sortString: '-date_from,name,',
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      priceProjects: 'getPriceProjects',
      priceProjectsStatus: 'getPriceProjectsStatus',
      modalEditPriceProjects: 'getModalEditPriceProjects',
      channelsList: 'getChannelsList',
      isLocale: 'isLocale',
    }),
    canView: function () {
      return this.$checkPermissions('price_project.read');
    },
    canEdit: function () {
      return this.$checkPermissions('price_project.update');
    },
    canDelete: function () {
      return this.$checkPermissions('price_project.delete');
    },
  },

  watch: {
    priceProjects() {
      this.setTableData();
    },

    'filters.type'() {
      if (!this.loading) this.pageClick(1);
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.priceProjects') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getPriceProjectInfo();
  },
  destroyed() {
    this.$store.commit('clearPriceProjects');
  },
  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_PRICE_PROJECTS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements ? this.searchElements : null,
        'filter[channel_id]': this.filters.channel && this.filters.channel.id ? +this.filters.channel.id : null,
        'filter[date_from]': this.filters.date_from ? this.filters.date_from : null,
        'filter[date_to]': this.filters.date_to ? this.filters.date_to : null,
        'filter[type]': this.filters.type ? this.filters.type : null,
        sort: this.sortString ? this.sortString : null,
        include: 'channel',
      });
      this.updateQuery();
    },

    filterDateFrom() {
      if (this.filters.date_to && new Date(this.filters.date_from) > new Date(this.filters.date_to)) {
        // Rewrite date TO if it is lower than FROM
        this.filters.date_to = this.filters.date_from;
      }
      this.pageClick(1);
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },

    // get table info
    setTableData() {
      if (this.priceProjects) {
        this.tableData = this.priceProjects.data;
        this.paginationData = this.priceProjects.pagination;
        if (this.filterPage > this.priceProjects.pagination.last_page) {
          this.filterPage = this.priceProjects.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },

    async getPriceProjectInfo() {
      await this.$store.dispatch('GET_CHANNELS', { per_page: 1000 });
      this.filterChannelsList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.channelsList];
      await this.restoreFromQuery('priceProjects');
      await this.paramsData();
      this.loading = false;
    },

    async showModalPriceProjects(type, item) {
      this.modalType = type;
      if (item) {
        this.currentPriceProject = item;
      }
      if (type !== 'edit') {
        this.$store.commit('clearModalEditPriceProjects');
      }
      this.$bvModal.show('price-project-modal');
    },

    showDeleteModalPriceProjects(item) {
      this.currentPriceProject = item;
      this.$bvModal.show('price-project-delete-modal');
    },
  },
};
</script>

<style lang="sass" scoped>
table thead
  .resizable-div__name
    overflow: auto
    min-width: 300px
  &:hover .resizable-div__name
    resize: horizontal

tbody > tr > td:first-child
  max-width: 300px
  width: 300px
  overflow: hidden
  text-overflow: ellipsis

.price-projects-page .vocabulary-table
  max-height: calc(100vh - 200px)
</style>
